.pagination {
  display: flex;
  list-style: none;
  outline: none;
  font-family: "Roboto Flex";

  next {
    color: red !important;
  }
}
.pagination > .active > a {
  background-color: #24282D;
  color: rgba(129, 254, 183, 1);
}
.pagination > .next > li > a {
    color: red !important;
}
.pagination > li > a {
  border: none;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  margin-left: 8px;
  margin-right: 8px;
  font-family: "Roboto Flex";

  @media (max-width:960px) {
    font-size: 20px;
    margin-right: 4px;
    margin-left: 4px;
    padding: 3px 6px;
  }
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #24282D;
  border-color: none;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #fff;
}

.pagination > .nextButton > a {
  font-weight: 600;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}
