body {
  margin: 0;
  background: #1C1F23;
}

a {
  text-decoration: none;
}

[data-rsbs-backdrop] {
  z-index: 1000000;
}

[data-rsbs-overlay],
[data-rsbs-root]:after {
  background: #1C1F23;
  z-index: 1000001;
  max-height: 90vh;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  scrollbar-width: none;
}

/* width */
/* ::-webkit-scrollbar {
  width: 10px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  background:#2b2f35;

} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #3f4549;
  border-radius: 50px;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #4a5055;
} */
.proposal-details a {
  color: #81feb7;
  cursor: pointer;
}
.proposal-details a:hover {
  text-decoration: underline;
  /* border-bottom: 1px solid #81feb7; */
}

:root {
  --rsbs-backdrop-bg: rgba(0, 0, 0, 0.6);
  --rsbs-bg: #1c2024;
  --rsbs-handle-bg: hsla(0, 0%, 0%, 0.14);
  --rsbs-max-w: auto;
  --rsbs-ml: env(safe-area-inset-left);
  --rsbs-mr: env(safe-area-inset-right);
  --rsbs-overlay-rounded: 16px;
}